// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-prismic-article-uid-tsx": () => import("./../../../src/pages/article/{PrismicArticle.uid}.tsx" /* webpackChunkName: "component---src-pages-article-prismic-article-uid-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-genese-tsx": () => import("./../../../src/pages/genese.tsx" /* webpackChunkName: "component---src-pages-genese-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inspirations-tsx": () => import("./../../../src/pages/inspirations.tsx" /* webpackChunkName: "component---src-pages-inspirations-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-mix-tsx": () => import("./../../../src/pages/mix.tsx" /* webpackChunkName: "component---src-pages-mix-tsx" */)
}

